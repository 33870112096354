.join{
    display: flex;
    padding: 0 2rem;
    gap: 10rem;
}
.left-j{
    color: white;
     font-size: 3rem;
     font-weight: bold;
     text-transform: uppercase;
     position: relative;
}
.left-j>hr{
  position: absolute;  
  width: 10.5rem;
  border: 2px solid var(--orange);
  border-radius: 20%;
  margin: -10px 0;
}
.email-container{
    display: flex;
    gap: 3rem;
    background-color: gray;
    padding: 1rem 2rem;

}
.email-container>input{
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 1rem;
    color: var(--lightgray);
}
::placeholder{
    color: var(--lightgray);
}
.email-container>button{
    padding: 0.5rem 1rem;
    border-radius: 5px;
    background-color: var(--orange);
    outline: none;
    border: none;
    cursor: pointer;
    color: white;
    font-weight: bold;

}
/* media queries */
@media screen and (max-width:780px) {
    .join{
        flex-direction: column;
        gap: 1rem;
    }
    .left-j{
        font-size: x-large;
        flex-direction: column;
    }
    .email-container{
        padding: 1rem 0.5rem; 
    
    }
    
    .email-container>button{
        margin-right: 2rem;
    }
}