.hero {
  display: flex;
  justify-content: space-between;

}
.left-h {
  padding: 2rem 1.5rem;
  flex: 3;
}
.right-h {
  flex: 1;
  position: relative;
  background-color: var(--orange);
 
}
.the-best-ad {
  margin-top: 4rem;
  background-color: #363d42;
  border-radius: 4rem;
  width: fit-content;
  padding: 20px 13px;
  text-transform: uppercase;
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.the-best-ad > span {
  z-index: 2;
}
.the-best-ad > div {
  position: absolute;
  width: 5.4rem;
  left: 8px;
  border-radius: 3rem;
  height: 80%;
  z-index: 1;
  background-color: var(--orange);
}
.hero-text {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-transform: uppercase;
  font-size: 4.5rem;
  font-weight: bold;
  color: white;
  text-overflow: inherit;
}
.hero-text > div:nth-child(3) {
  font-size: 1rem;
  font-weight: 200;
  text-transform: none;
  letter-spacing: 1px;
  width: 80%;
}
.figures{
    margin-top: 2rem;
    display: flex;
    gap: 2rem;
}
.figures>div{
    display: flex;
    flex-direction: column;
}
.figures>div>span:nth-of-type(1){
    color: white;
    font-size: 2rem;
}

.figures>div>span:nth-of-type(2){
    color:var(--gray);
    /* font-size: 2rem; */
    text-transform: uppercase ;
}
.hero-buttons{
    display: flex;
    margin-top: 1.5rem;
    gap: 2rem;
    font-weight: normal;
}
.hero-buttons>:nth-child(1){
    color: white;
    background-color: var(--orange);
    width: 8rem;
    border-radius: 2px;
}
.hero-buttons>:nth-child(2){
    color: white;
    background-color:transparent;
    width: 8rem;
    border-radius: 2px;
    border: 2px solid var(--orange);
}
.right-h>.btn{
    position: absolute;
    right: 3rem;
    top: 2rem;
    color: black ;
}
.heart-rate{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--darkGrey);
  width: fit-content;
  padding: 1rem;
  align-items: start;
  border-radius: 5px;
  position: absolute;
  right: 4rem;
  top: 7rem;
}
.heart-rate>img{
  width: 2rem;
}
.heart-rate>span:nth-child(2){
    color: var(--gray);
    
}
.heart-rate>span:nth-child(3){
  color: white;
  font-size: 1.5rem;
  
}
.hero-image{
  position: absolute;
  right: 10rem;
  top: 12rem;
  width:23rem;
}

.hero-image-back{
  position: absolute;
  right: 20rem;
  top: 4rem;
  width:15rem;
  z-index: -1;
}
.calories{
  display: flex;
  gap: 2rem;
  background-color: var(--caloryCard);
  border-radius: 5px;
  padding: 1rem;
  width: fit-content;
  position: absolute;
  top: 32rem;
  right: 30rem;
}
.calories > img{
  width: 3rem;
}
.calories > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.calories>div>span:nth-child(1){
  color: var(--gray);
}
.calories>div>span:nth-child(23){
  color: white;
  font-size: 1.5rem;
}
.hero-blur{
  width: 22rem;
  height: 30rem;
  left: 0;
}
/* media queries */
@media screen and (max-width:780px) {
  .hero{
    flex-direction: column;
    justify-content: center;
    
  }
  .hero-blur{
    width: 14rem;
  }
  .the-best-ad{
    margin-top: 0;
    margin-left: 45px;
    font-size: small;
    align-items: center;
    transform: scale(0.8);
  }
  .hero-text{
    font-size: xx-large;
    align-items: center;
    justify-content: center;
  }
  .hero-text>div> .stroke{
    font-size: 2rem;
  }
 
  .hero-text>div:nth-last-of-type(3){
    font-size: small;
    font-weight: 200;
    letter-spacing: 1px;
    text-align: center;
  }
  .hero-buttons{
    justify-content: center;
  }
  .figures>div> span:nth-last-of-type(1){
    font-size: large;
  }
  .figures>div> span:nth-last-of-type(2){
    font-size: small;
  }
  .right-h{
    position: relative;
    background: none;
  }
  .heart-rate{
    left: 1rem;
    top: 2rem;
  }
  .calories{
    position: relative;
    top: 5rem;
    left: 2rem;
  }
  .calories > div> :nth-child(2){
    color: white;
    font-size: 1rem;
  }
  .hero-image{
    position: relative;
    width: 15rem;
    left: 7rem;
    top: 4rem;
    align-self: center;
  }
  .hero-image-back{
    width:15rem ;
    left: 2rem;
    top: 0rem;

  }
}